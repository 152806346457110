import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { insertData } from '../../supabaseClient';
import OrderConfirmedDialog from './OrderConfirmedDialog';
import PopupProgressLoader from './PopupProgressLoader';
import supabase from '../../supabaseClient';
import axios from 'axios';
import './OrderSummary.css';

const OrderSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderData } = location.state || {};

  const shop = sessionStorage.getItem('shopDetails')
    ? JSON.parse(sessionStorage.getItem('shopDetails'))
    : orderData?.shop;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [userUid, setUserUid] = useState(null);
  const [userTag, setUserTag] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);
  const [updatedFiles, setUpdatedFiles] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isOrderComplete, setIsOrderComplete] = useState(false);

  useEffect(() => {
    const loadRazorpayScript = () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => setIsRazorpayLoaded(true);
      document.body.appendChild(script);
    };

    loadRazorpayScript();

    const fetchUserInfo = async () => {
      const storedUserInfo = localStorage.getItem('userInfo');
      if (storedUserInfo) {
        const { sub, name, email } = JSON.parse(storedUserInfo);
        const { data, error } = await supabase
          .from('users')
          .select('uid, tag')
          .eq('google_user_id', sub)
          .single();

        if (error) {
          console.error('Error fetching user info:', error);
          setError('Failed to fetch user information. Please try again.');
        } else if (data) {
          setUserUid(data.uid);
          setUserTag(data.tag);
          setOrderDetails({
            name,
            email,
          });
        }
      }
    };

    fetchUserInfo();


  }, []);

  useEffect(() => {
    if (updatedFiles) {
      const runProcessOrder = async () => {
        try {
          await processOrder(updatedFiles);
          setIsOrderComplete(true);
        } catch (error) {
          console.error('Error processing order:', error);
          setError('Failed to process order. Please contact support.');
        }
      };

      runProcessOrder();
    }
  }, [updatedFiles]);

  const calculatePageCounts = (file) => {
    const startPage = parseInt(file.fileSettings.start_page) || 1;
    const endPage = parseInt(file.fileSettings.end_page) || 1;
    const copies = parseInt(file.fileSettings.copies) || 1;
    const pages = endPage - startPage + 1;
    const totalPages = pages * copies;
    return { pages, totalPages };
  };

  const getFilesFromStorage = () => {
    try {
      const filesString = localStorage.getItem('uploadedFiles');
      if (!filesString) return [];

      const parsedFiles = JSON.parse(filesString);
      // Handle both array and single object cases
      return Array.isArray(parsedFiles) ? parsedFiles : [parsedFiles];
    } catch (error) {
      console.error('Error parsing files from localStorage:', error);
      return [];
    }
  };

  const files = getFilesFromStorage();

  const calculateFileCost = (file) => {
    const { totalPages } = calculatePageCounts(file);
    const colorCost = 5;
    const bwCost = 3;
    return file.fileSettings.color_mode === 'Color' ? totalPages * colorCost : totalPages * bwCost;
  };

  const calculateTotalCost = () => {
    return files.reduce((totalCost, file) => totalCost + calculateFileCost(file), 0);
  };

  const totalCost = calculateTotalCost();

  // const serverURL = process.env.SERVER_URL;  
  const generateOrderId = async () => {
    try {
      const order_url = 'https://ctrlp-payment-server-eight.vercel.app/create-order';
      const response = await axios.post(order_url, {
        amount: calculateTotalCost(),
        currency: 'INR',
        receipt: `receipt_${Date.now()}`,
        notes: {
          uid: userUid,
          shop_id: shop.id,
        }
      });
      return response.data.orderId;
    } catch (error) {
      console.error('Error generating order:', error);
      throw new Error('Failed to generate order. Please try again.');
    }
  };

  const processOrder = async (files) => {
    for (const file of files) {
      const currentDateTime = new Date().toISOString();
      const filePath = file.pdf_link ? file.pdf_link : file.fileName;
      const orderDetails = {
        created_at: currentDateTime,
        ...file.fileSettings,
        files: filePath,
        shop_id: shop.id,
        size: file.size,
        status: 'UPLOADED',
        uid: userUid,
        combined_file: file.combined_file
      };
      const { data, error } = await insertData(orderDetails, file.source);

      if (error) {
        throw error;
      }
      console.log('Order submitted successfully:', data);
    }

    localStorage.removeItem('uploadedFiles');
    localStorage.removeItem('savedPreferences');
  };

  const handleRazorpayCheckout = (orderId) => {
    if (!isRazorpayLoaded) {
      setError('Payment system is not ready. Please try again in a moment.');
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: calculateTotalCost() * 100, // Razorpay expects amount in paise
      currency: 'INR',
      name: 'CTRL+P',
      description: 'Payment for printing services',
      order_id: orderId,
      prefill: {
        name: orderDetails.name,
        email: orderDetails.email,
        contact: orderDetails.phone || '',
      },
      notes: {
        address: orderDetails.address || ''
      },
      theme: {
        color: '#3399cc'
      },
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      modal: {
        ondismiss: function () {
          setPaymentStatus('cancelled');
          setError('Payment was cancelled. Please try again.');
        }
      },
      method: {
        netbanking: true,
        card: true,
        wallet: true,
        upi: true,
        gpay: true
      },
      redirect: false
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleCreateInvoice = async (response) => {
    try {
      const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      const fileCosts = uploadedFiles.map(file => calculateFileCost(file));
      const totalCost = fileCosts.reduce((sum, cost) => sum + cost, 0);

      const url = 'https://ctrlp-payment-server-eight.vercel.app/create-invoice';
      const body = JSON.stringify({
        razorpay_order_id: response,
        uploadedFiles: uploadedFiles,
        userInfo: userInfo,
        fileCosts: fileCosts,
        totalCost: totalCost
      });

      const InvoiceResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      console.log(InvoiceResponse)

      if (!InvoiceResponse.ok) {
        console.log('Invoice response not ok')
        console.error('Server responded with an error:', InvoiceResponse.status, InvoiceResponse.statusText);
        const errorBody = await InvoiceResponse.text();
        console.error('Error body:', errorBody);
        throw new Error(`HTTP error! status: ${InvoiceResponse.status}`);
      }

      const InvoiceData = await InvoiceResponse.json();

      if (InvoiceData.success) {
        setUpdatedFiles(InvoiceData.updatedFiles);
      } else {
        console.error('Invoice creation failed:', InvoiceData.message);
        setError(`Invoice creation failed: ${InvoiceData.message}`);
      }
    } catch (err) {
      console.error('Error processing payment or order:', err);
      setError('Invoice creation successful, but  order processing failed. Please contact support.');
    }
  };

  const handleSubmitOrder = async () => {
    if (loading) return;
    setLoading(true);
    setError(null);
    try {
      if (!userUid) {
        throw new Error('User information not available. Please log in again.');
      }

      if (userTag === 'user') {
        const orderId = await generateOrderId();
        setOrderDetails(prev => ({ ...prev, orderId }));
        handleRazorpayCheckout(orderId);
      } else {
        setShowLoader(true);
        const orderId = await generateOrderId();
        setOrderDetails(prev => ({ ...prev, orderId }));
        handleCreateInvoice(orderId);
      }
    } catch (err) {
      console.error('Error submitting order:', err);
      setError(err.message || 'Failed to submit order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = () => {
    console.log('Loading complete!');
  };

  const handlePaymentSuccess = async (response) => {
    setPaymentStatus('processing');
    setShowLoader(true);

    try {
      const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      const fileCosts = uploadedFiles.map(file => calculateFileCost(file));
      const totalCost = fileCosts.reduce((sum, cost) => sum + cost, 0);

      const url = 'https://ctrlp-payment-server-eight.vercel.app/verify-payment';
      const body = JSON.stringify({
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
        uploadedFiles: uploadedFiles,
        userInfo: userInfo,
        fileCosts: fileCosts,
        totalCost: totalCost
      });

      const verificationResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
      console.log(verificationResponse);

      if (!verificationResponse.ok) {
        console.log('verification response not ok')
        console.error('Server responded with an error:', verificationResponse.status, verificationResponse.statusText);
        const errorBody = await verificationResponse.text();
        console.error('Error body:', errorBody);
        throw new Error(`HTTP error! status: ${verificationResponse.status}`);
      }

      const verificationData = await verificationResponse.json();

      if (verificationData.success) {
        console.log('Payment verified successfully');
        setPaymentStatus('success');
        setUpdatedFiles(verificationData.updatedFiles);
      } else {
        console.error('Payment verification failed:', verificationData.message);
        setPaymentStatus('failed');
        setError(`Payment verification failed: ${verificationData.message}`);
      }
    } catch (err) {
      console.error('Error processing payment or order:', err);
      setPaymentStatus('failed');
      setError('Payment successful, but verification or order processing failed. Please contact support.');
    }
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
    navigate('/');
  };

  const handleCancelOrder = () => {
    navigate('/shop-selection');
  };

  if (!shop || !files) {
    return <div>No order data available. Please go back and select a shop.</div>;
  }

  return (
    <div className="order-summary-container">
      <div className="order-summary-card">
        <div className="order-header">
          <h2 className="order-title">Order Summary</h2>
        </div>

        <div className="shop-details-card">
          <h3 className="section-title">
            <i className="far fa-store"></i>
            Selected Shop
          </h3>
          <p className="shop-name-summary">{shop.name} - {shop.institute}</p>
          {/* <div className="shop-status">
            <span className="status-dot"></span>
            <p className="shop-workload">{shop.trafficStatus.text}</p>
          </div> */}
        </div>

        <div className="files-section">
          <h3 className="section-title">
            <i className="far fa-file-alt"></i>
            Files to be Printed
          </h3>
          <div className="files-list">
            {files.map((file, index) => {
              const { pages, totalPages } = calculatePageCounts(file);
              const fileCost = calculateFileCost(file);

              return (
                <div key={index} className="file-card">
                  <div className="file-header">
                    <h4 className="file-title">File {index + 1}</h4>
                    <span className="file-type">{file.fileName.split('.').pop().toUpperCase()}</span>
                  </div>

                  <div className="file-content">
                    <div className="file-info-grid">
                      <div className="info-group">
                        <div className="info-item">
                          <span className="info-label">File Name</span>
                          <span className="info-value">{file.fileName}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Pages per Copy</span>
                          <span className="info-value">{pages}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Copies</span>
                          <span className="info-value">{file.fileSettings.copies || '1'}</span>
                        </div>
                      </div>

                      <div className="info-group">
                        <div className="info-item">
                          <span className="info-label">Total Pages</span>
                          <span className="info-value">{totalPages}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Color Mode</span>
                          <span className="info-value">{file.fileSettings.color_mode || 'B&W'}</span>
                        </div>
                        <div className="info-item">
                          <span className="info-label">Duplex</span>
                          <span className="info-value">{file.fileSettings.duplex || 'Single Side'}</span>
                        </div>
                      </div>
                    </div>

                    <div className="file-cost-section">
                      <span className="file-label">Cost</span>
                      <span className="file-amount">₹{fileCost}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="total-cost-section">
          <span className="total-label">Total Cost</span>
          <span className="total-amount">₹{totalCost}</span>
        </div>

        {error && (
          <div className="error-message">{error}</div>
        )}

        <div className="action-buttons">
          <button
            onClick={handleSubmitOrder}
            disabled={loading}
            className="confirm-button"
          >
            {loading ? 'Processing...' : 'Confirm and Pay'}
          </button>
          <button
            onClick={handleCancelOrder}
            disabled={loading}
            className="cancel-button"
          >
            Cancel
          </button>
        </div>

        {showLoader && (
          <PopupProgressLoader
            isComplete={isOrderComplete}
            onComplete={() => {
              setShowLoader(false);
              setShowConfirmDialog(true);
            }}
          />
        )}

        {showConfirmDialog && (
          <OrderConfirmedDialog onConfirm={handleConfirmDialogClose} />
        )}

        {paymentStatus === 'success' && (
          <div className="success-message">
            Payment successful! Your order has been placed.
          </div>
        )}

        {paymentStatus === 'failed' && (
          <div className="error-message">
            Payment failed. Please try again or contact support.
          </div>
        )}
      </div>

      {/* Loader and Dialog components remain unchanged */}
    </div>
  );
};

export default OrderSummary;