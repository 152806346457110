import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrintStore from './Pages/PrintStore/PrintStore';
import ShopSelection from './Pages/ShopSelection/ShopSelection';
import Home from './Pages/HomePage/Home';
import './App.css';
import Navbar from './Pages/HomePage/Navbar/Navbar';
import OrderSummary from './Pages/OrderSummary/OrderSummary';
import ProductPage from './Pages/PrintStore/ProductPage';
import Footer from './Pages/HomePage/Footer/Footer';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import { AuthProvider } from './AuthContext';
import { useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey);

const HomeWithQRHandler = () => {
  useEffect(() => {
    const handleQRScan = async () => {
      const params = new URLSearchParams(window.location.search);
      const shopId = params.get('shop_id');

      if (shopId) {
        try {
          const { data, error } = await supabase
            .from('institute_print_shops')
            .select('shop_id,shop_name, institute_name')
            .eq('shop_id', shopId)
            .single();

          if (error) throw error;

          if (data) {
            sessionStorage.setItem('shopDetails', JSON.stringify({
              id: data.shop_id,
              name: data.shop_name,
              institute: data.institute_name
            }));
            window.history.replaceState({}, '', '/');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    handleQRScan();
  }, []);

  return <Home />;
};

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <Router>
          <Navbar />
          <div className="App">
            <Routes>
              <Route path="/" element={<HomeWithQRHandler />} />
              <Route path="/print-store" element={<PrintStore />} />
              <Route path="/product/:id" element={<ProductPage />} />
              <Route path="/shop-selection" element={<ShopSelection />} />
              <Route path="/order_confirmation" element={<OrderSummary />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider >
  );
}

export default App;
