import React from 'react'
import logo from '../../../assets/CTRL_P.png'
import { Link } from 'react-router-dom'
const Footer = () => {
    const footerStyle = {
        backgroundColor: '#0b0d1f',
        color: '#ffffff',
        textAlign: 'center',
        padding: '32px 0',
        fontSize: '14px',
        marginTop: 'auto',
        fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, sans-serif"
    }

    return (
        <div id="footer" style={footerStyle}>
            <img src={logo} height={25} />
            <p>© 2024, CTRL + P. All Rights Reserved.</p>
            <Link to="/terms-and-conditions" style={{color:'white'}}>Terms and Conditions</Link>
        </div>
    )
}

export default Footer
