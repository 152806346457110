import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import search_icon from '../../assets/search_icon.png';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import supabase from '../../supabaseClient';
import './PrintStore.css';


const PrintStore = () => {
  const [category, setCategory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSearchQuery, setTempSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    types: []
  });

  const dropdownRefs = {
    categories: useRef(null),
    types: useRef(null)
  };

  const filterOptions = {
    categories: ['Engineering', 'Medical', 'Law'],
    types: ['Notes', 'Cheatsheet']
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(dropdownRefs).forEach(([key, ref]) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropdown(null);
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchItems = useCallback(async () => {
    setLoading(true);

    try {
      let query = supabase
        .from('print_bazaar')
        .select('*')
        .eq('approved', 'true')

      if (selectedFilters.categories.length > 0) {
        query = query.in('category', selectedFilters.categories);
      }

      if (selectedFilters.types.length > 0) {
        query = query.in('type', selectedFilters.types);
      }

      if (searchQuery) {
        query = query.or(
          `file_name.ilike.%${searchQuery}%,` +
          `author_name.ilike.%${searchQuery}%,` +
          `category.ilike.%${searchQuery}%`
        );
      }

      const { data, error } = await query;

      if (error) throw error;
      setCategory(data || []);
    } catch (error) {
      console.error('Error fetching items:', error.message);
      setCategory([]);
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, searchQuery]);

  useEffect(() => {
    fetchItems();
  }, [selectedFilters, searchQuery, fetchItems]);

  const toggleFilter = (filterType, value) => {
    setSelectedFilters((prev) => {
      const currentFilters = prev[filterType];
      const newFilters = currentFilters.includes(value)
        ? currentFilters.filter((f) => f !== value)
        : [...currentFilters, value];
  
      return {
        ...prev,
        [filterType]: newFilters,
      };
    });
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleSearch = () => {
    setSearchQuery(tempSearchQuery);
  };

  const handleReset = () => {
    setSelectedFilters({
      categories: [],
      types: []
    });
    setSearchQuery('');
    setTempSearchQuery('');
    setOpenDropdown(null);
  };

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#111827',
      }}>
        <CircularProgress sx={{ color: '#3b82f6' }} size={60} />
      </Box>
    );
  }

  return (
    <div className="printstore-container">
      {/* Search and Filter Section */}
      <div className="search-filter-section">
        {/* Dropdown Filters */}
        <div className="filter-dropdowns">
          {Object.entries(filterOptions).map(([filterType, options]) => (
            <div
              key={filterType}
              className="filter-dropdown"
              ref={dropdownRefs[filterType]}
            >
              <button
                className={`dropdown-trigger ${openDropdown === filterType ? 'active' : ''}`}
                onClick={() => toggleDropdown(filterType)}
              >
                {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                <span className="dropdown-icon">▼</span>
              </button>
              {openDropdown === filterType && (
                <div
                  className="dropdown-content"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {options.map((option) => (
                    <label
                      key={option}
                      className={`checkbox-label ${
                        selectedFilters[filterType].includes(option) ? 'selected' : ''
                      }`}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        toggleFilter(filterType, option);
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedFilters[filterType].includes(option)}
                        readOnly
                      />
                      <span>{option}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Search Input */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by Title or Author"
            value={tempSearchQuery}
            onChange={(e) => setTempSearchQuery(e.target.value)}
            className="search-input"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <button 
            onClick={handleSearch} 
            className="search-icon-button"
            aria-label="Search"
          >
            <img src={search_icon} width={15} alt='search_icon'></img>
          </button>
          <button 
            onClick={handleReset} 
            className="reset-icon-button"
            aria-label="Reset"
          >
           X
          </button>
        </div>
      </div>

      {/* Banner */}
      <div className="banner">
        <img
          src="https://gxbdltjmtvrtsomvbrgv.supabase.co/storage/v1/object/public/print-store/home.jpg"
          alt="Print Store Banner"
        />
      </div>

      {/* Items Display */}
      <h2 className="items-heading">
        {selectedFilters.categories.length > 0
          ? `${selectedFilters.categories.join(', ')} Products`
          : 'All Products'}
      </h2>

      <div className="products-grid">
        {category.length > 0 ? (
          category.map((book) => (
            <div key={book.id} className="product-card">
              <img src={book.cover_image_url} alt={book.file_name} className="product-cover" />
              <div className="product-info">
                <h3>{book.file_name}</h3>
                <p>{book.author_name}</p>
                <p>{book.category}</p>
                <Link to={`/product/${book.id}`} className="view-button">View</Link>
              </div>
            </div>
          ))
        ) : (
          <p className="no-results">
            {selectedFilters.categories.length === 0 && !searchQuery
              ? 'Select filters or search to display products'
              : 'No products found'}
          </p>
        )}
      </div>
    </div>
  );
};

export default PrintStore;