import React, { useRef, useState, useEffect  } from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    const [showScrollTop, setShowScrollTop] = useState(false);
    const sectionRefs = useRef([]);
    const sections = [
        {
            title: "Introduction and Acceptance of Terms",
            content: (<>
                <p>
                    Welcome to CTRL P. Please read these Terms and Conditions ("Terms") carefully before using the CTRL P website, mobile application, or any related services (collectively, the "Service") operated by CTRL P ("us", "we", "our", or "CTRL P").
                </p>
                <p>
                    Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access or use the Service.</p>
            </>)
        },
        {
            title: "Definitions",
            content: (
                <ul>
                    <li><b>"Service"</b> refers to the CTRL P website, mobile application, and all related services provided by CTRL P.</li>
                    <li><b>"User", "you", or "your"</b> refers to the individual or entity using the Service.</li>
                    <li><b>"Content"</b> refers to any information, data, text, files, or material uploaded or submitted by users through the Service.</li>
                    <li><b>"Store" or "Vendor"</b> refers to the third-party printing locations affiliated with CTRL P.</li>
                </ul>
            )
        },
        {
            title: "Service Description",
            content: (
                <div>
                    <p>
                        <b>3.1.</b> CTRL P is a cloud-based printing service that allows users to upload documents, customize print settings, and schedule printing at participating stores. We facilitate the connection between users and printing stores but do not provide printing or delivery services directly.
                    </p>
                    <p>
                        <b>3.2.</b> Our Service includes, but is not limited to:
                        <br /> a) Document upload and storage
                        <br /> b) Print customization options
                        <br /> c) Store location services
                        <br /> d) Scheduling and order management
                        <br /> e) Payment processing
                    </p>
                    <p>
                        <b>3.3.</b> CTRL P does not provide delivery services. Users are responsible for collecting their prints from the selected store.
                    </p>
                    <p>
                        <b>3.4.</b> We offer two distinct models:
                        <br /> a) <b>Institute Model:</b> Designed for schools, colleges, and educational institutions.
                        <br /> b) <b>Commercial Model:</b> Tailored for individual students and general users.
                    </p>
                    <p>
                        <b>3.5.</b> <b>Print Bazaar Feature:</b> A pre-loaded collection of notes and study materials available directly in the system for printing.
                    </p>
                </div>
            )
        },
        {
            title: "User Accounts and Registration",
            content: (
                <div>
                    <p>
                        <b>4.1.</b> To access certain features of the Service, you must create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                    </p>
                    <p>
                        <b>4.2.</b> You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password.
                    </p>
                    <p>
                        <b>4.3.</b> You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                    </p>
                    <p>
                        <b>4.4.</b> You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar, or obscene.
                    </p>
                </div>
            )
        },
        {
            title: "Use of the Service",
            content: (
                <div>
                    <p>
                        <b>5.1.</b> You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
                        <br /> a) In any way that violates any applicable national or international law or regulation.
                        <br /> b) To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter", "spam", or any other similar solicitation.
                        <br /> c) To impersonate or attempt to impersonate CTRL P, a CTRL P employee, another user, or any other person or entity.
                        <br /> d) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                    </p>
                    <p>
                        <b>5.2.</b> You are solely responsible for any Content you upload to our Service. You represent and warrant that:
                        <br /> a) You own or control all rights in and to the Content and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.
                        <br /> b) All of your Content does not and will not violate rights of any third party, including copyright, trademark, privacy, personality, or other personal or proprietary rights.
                        <br /> c) The Content does not contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
                    </p>
                    <p>
                        <b>5.3.</b> We reserve the right to refuse service, terminate accounts, remove or edit Content, or cancel orders at our sole discretion.
                    </p>
                </div>
            )
        },
        {
            title: "Intellectual Property Rights",
            content: (
                <div>
                    <p>
                        <b>6.1.</b> The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of CTRL P and its licensors.
                        The Service is protected by copyright, trademark, and other laws of both India and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service
                        without the prior written consent of CTRL P.
                    </p>
                    <p>
                        <b>6.2.</b> You retain all rights to the Content you upload to our Service. By uploading Content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such Content in connection with providing and promoting the Service.
                    </p>
                    <p>
                        <b>6.3.</b> You represent and warrant that you have all rights, power, and authority necessary to grant the rights granted herein to any Content that you submit.
                    </p>
                </div>
            )
        },
        {
            title: "Privacy and Data Protection",
            content: (
                <div>
                    <p>
                        <b>7.1.</b> Your privacy is important to us. Our Privacy Policy, available at{" "}
                        <a href="https://ctrlp.co.in/privacy" target="_blank" rel="noopener noreferrer">
                            https://ctrlp.co.in/privacy
                        </a>
                        , explains how we collect, use, and disclose information about you. By using the Service, you agree to the collection and use of information in accordance with our Privacy Policy.
                    </p>
                    <p>
                        <b>7.2.</b> We will delete your uploaded files from our servers immediately after printing is completed to ensure the privacy and security of your data.
                    </p>
                    <p>
                        <b>7.3.</b> You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.
                    </p>
                </div>
            )
        },
        {
            title: "Payments, Pricing, and Refunds",
            content: (
                <div>
                    <p>
                        <b>8.1.</b> All payments are processed securely through our third-party payment providers. By using the Service, you agree to provide accurate and complete payment information.
                    </p>
                    <p>
                        <b>8.2.</b> Current pricing for our services is as follows:
                        <ul>
                            <li>Black & White: ₹3 per page</li>
                            <li>Color: ₹20 per page</li>
                        </ul>

                    </p>
                    <p>
                        <b>8.3.</b> Prices for our services are subject to change without notice. We reserve the right to modify or discontinue the Service without notice at any time.
                    </p>
                    <p>
                        <b>8.4.</b> You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order.
                    </p>
                    <p>
                        <b>8.5.</b> Refund Policy:
                        <br /> a) Refunds may be issued at our discretion, typically in cases of service failure or printing errors on our part.
                        <br /> b) Refund processing may take 7-9 working days from the date of approval.
                        <br /> c) We do not provide refunds for issues or problems arising from store-related inconveniences or errors. While stores are our vendors, they operate under their own policies, and CTRL P is not responsible for store-specific issues.
                    </p>
                    <p>
                        <b>8.6.</b> We are not responsible for any additional fees, charges, or taxes imposed by your bank or payment provider.
                    </p>
                </div>
            )
        },
        {
            title: "Print Collection and Store Policies",
            content: (
                <div>
                    <p>
                        <b>9.1.</b> CTRL P facilitates the printing process but does not own or operate the printing stores. Each store is an independent vendor with its own policies and procedures.
                    </p>
                    <p>
                        <b>9.2.</b> You are responsible for collecting your prints from the store you selected during the ordering process. CTRL P does not provide delivery services.
                    </p>
                    <p>
                        <b>9.3.</b> Store policies, including but not limited to operating hours, print collection procedures, and customer service practices, are set by individual stores and may vary. CTRL P is not responsible for any inconvenience, delay, or loss resulting from store-specific policies or practices.
                    </p>
                    <p>
                        <b>9.4.</b> In case of any issues with print quality, store service, or other store-related problems, you should first attempt to resolve the issue directly with the store. CTRL P will not provide refunds or compensation for store-related issues as outlined in Section 8.5.
                    </p>
                    <p>
                        <b>9.5.</b> CTRL P reserves the right to remove any store from our network of vendors at our discretion and without notice.
                    </p>
                </div>)
        },
        {
            title: "Limitation of Liability",
            content: (
                <div>
                    <p>
                        <b>10.1.</b> To the maximum extent permitted by law, in no event shall CTRL P, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, punitive, incidental,
                        special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or relating to the use of, or
                        inability to use, the Service.
                    </p>
                    <p>
                        <b>10.2.</b> To the maximum extent permitted by law, CTRL P assumes no liability or responsibility for any:
                        <br />  a) Errors, mistakes, or inaccuracies of Content.
                        <br />  b) Personal injury or property damage, of any nature whatsoever, resulting from your access to or use of our Service.
                        <br />  c) Any unauthorized access to or use of our secure servers and/or any and all personal information stored therein.
                        <br />  d) Any interruption or cessation of transmission to or from the Service.
                        <br />  e) Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party.
                        <br />  f) Any errors or omissions in any Content or for any loss or damage incurred as a result of the use of any Content posted, emailed, transmitted, or otherwise made available through the Service.
                    </p>
                    <p>
                        <b>10.3.</b> CTRL P is not responsible for the quality of prints, availability of stores, or any issues arising from the collection of prints from stores.
                    </p>
                    <p>
                        <b>10.4.</b> In no event shall CTRL P, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any failure to perform its obligations hereunder where such failure results
                        from any cause beyond CTRL P's reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation.
                    </p>
                </div>

            )
        },
        {
            title: "Indemnification",
            content: (
                <div>
                    <p>
                        You agree to defend, indemnify, and hold harmless CTRL P and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages,
                        obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of: </p>
                    a) Your use and access of the Service, by you or any person using your account and password.<br />
                    b) A breach of these Terms.   <br />
                    c) Content posted on the Service.   <br />
                    d) Your violation of any term of these Terms.   <br />
                    e) Your violation of any third party right, including without limitation any copyright, property, or privacy right.   <br />
                    f) Any claim that your Content caused damage to a third party. <br />
                    <p>This defense and indemnification obligation will survive these Terms and your use of the Service.
                    </p>
                </div>

            )
        },
        {
            title: "Modifications to the Service",
            content: (
                <div>
                    <p>
                        <b>12.1.</b> We reserve the right to withdraw or amend our Service, and any service or material we provide via the Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period.
                    </p>
                    <p>
                        <b>12.2.</b> From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.
                    </p>
                </div>
            )
        },
        {
            title: "Termination",
            content: (
                <div>
                    <p>
                        <b>13.1.</b> We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                    </p>
                    <p>
                        <b>13.2.</b> All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                    </p>
                    <p>
                        <b>13.3.</b> Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
                    </p>
                </div>

            )
        },
        {
            title: "Governing Law and Dispute Resolution",
            content: (
                <div>
                    <p>
                        <b>14.1.</b> These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.
                    </p>
                    <p>
                        <b>14.2.</b> Any disputes arising out of or relating to these Terms or the Service will be subject to the exclusive jurisdiction of the courts located in [Indore], India.
                    </p>
                    <p>
                        <b>14.3.</b> You agree to first contact us at support.ctrlp@gmail.com and attempt to resolve the dispute informally by providing your name, a description of the problem, and contact information before taking any formal legal action.
                    </p>
                </div>

            )
        },
        {
            title: "Miscellaneous Provisions",
            content: (
                <div>
                    <p>
                        <b>15.1.</b> These Terms constitute the entire agreement between you and CTRL P regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.
                    </p>
                    <p>
                        <b>15.2.</b> If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
                    </p>
                    <p>
                        <b>15.3.</b> The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
                    </p>
                    <p>
                        <b>15.4.</b> The section titles in these Terms are for convenience only and have no legal or contractual effect.
                    </p>
                    <p>
                        <b>15.5.</b> You agree that these Terms will not be construed against us by virtue of having drafted them.
                    </p>
                    <p>
                        <b>15.6.</b> You hereby waive any and all defenses you may have based on the electronic form of these Terms and the lack of signing by the parties hereto to execute these Terms.
                    </p>
                </div>

            )
        },
        {
            title: "Contact Information",
            content: (
                <div>
                    <p>
                        If you have any questions about these Terms, please contact us:<br />
                        Email: <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a> or <a href="mailto:dev.ctrlp@gmail.com">dev.ctrlp@gmail.com</a>
                        <br /><br />
                        By using CTRL P, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.
                    </p>
                </div >

            )
        }
    ];

    const scrollToSection = (index) => {
        sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 200);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className="terms-container">
            <div className="main-content">
                <h1 className="main-title">Terms and Conditions</h1>
                {sections.map((section, index) => (
                    <div
                        key={index}
                        ref={(el) => (sectionRefs.current[index] = el)}
                        className="section"
                    >
                        <h2 className="section-title" id={`section-${index}`}>
                            {section.title}
                        </h2>
                        <p className="section-content">{section.content}</p>
                    </div>
                ))}
            </div>

            <div className="toc-container">
                <div className="toc-sticky">
                    <h3 className="toc-title">Table of Contents</h3>
                    <nav className="toc-nav">
                        {sections.map((section, index) => (
                            <button
                                key={index}
                                onClick={() => scrollToSection(index)}
                                className="toc-item"
                            >
                                {index + 1}. {section.title}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
            {showScrollTop && (
                <button
                    className="scroll-top-button"
                    onClick={scrollToTop}
                    aria-label="Scroll to top"
                >
                    ↑
                </button>
            )}
        </div>
    );
};

export default TermsAndConditions;